export const questions = [
  {
    title:
      "Hai preferenze per un <span class='text-red'>brand in particolare?</span>",
    description:
      "Puoi selezionare una o più opzioni, metteremo in primo piano i brand che hai selezionato. Se non hai preferenze particolari lascia vuota questa sezione.",
    shortName: "brands",
    canSkip: true,
    type: "checkbox",
  },
  {
    title: "Quanto vorresti <br><span class='text-red'>spendere?</span>",
    description:
      "Indica il prezzo minimo e il prezzo massimo che vuoi spendere, ti mostreremo solo i dispositivi in quella fascia di prezzo. Se non hai problemi per quanto riguarda il prezzo lascia i valori massimi e minimi.",
    shortName: "prezzo",
    canSkip: true,
    type: "doubleSlider",
    defaultValue: [100, 1500],
  },
  {
    title:
      "Quanto è importante <br><span class='text-red'>per te il display?</span>",
    description:
      "La qualità di un display è influenzata da caratteristiche quali luminosità, definizione dello schermo, qualità dell’immagine e refresh rate. Se assegni un voto più alto ti mostreremo dispositivi con display migliori, ma anche con un prezzo più elevato.",
    shortName: "display",
    type: "slider",
  },
  {
    title:
      "Quanto è importante <br><span class='text-red'>per te la fotocamera?</span>",
    description:
      "La qualità della fotocamera è influenzata da caratteristiche quali qualità dei colori, definizione e megapixel. Se assegni un voto più alto ti mostreremo dispositivi con fotocamere migliori, ma anche con un prezzo più elevato.",
    shortName: "fotocamera",
    type: "slider",
  },
  {
    title:
      "Quanto sono importanti <br><span class='text-red'>per te le prestazioni?</span>",
    description:
      "Le prestazioni sono valutate in base a test svolti direttamente sui devices, oltre che benchmark specifici. Se assegni un voto più alto ti mostreremo dispositivi con prestazioni migliori, ma anche con un prezzo più elevato.",
    shortName: "prestazioni",
    type: "slider",
  },
  {
    title: "Di quanta batteria <br><span class='text-red'>hai bisogno?</span> ",
    description:
      "L'efficienza della batteria è valutata in base alla durata media di un ciclo completo di ricarica. Se assegni un voto più alto ti mostreremo dispositivi con una maggiore durata della batteria, ma anche con un prezzo più elevato.",
    shortName: "batteria",
    type: "slider",
  },
  {
    title:
      "Quanto ti interessa la <br><span class='text-red'>componente hardware?</span> ",
    description:
      "L'hardware è valutato in base alla qualità e alle prestazioni delle varie componenti del dispositivo. Se assegni un voto più alto ti mostreremo dispositivi di maggiore qualità, ma anche con un prezzo più elevato.",
    shortName: "hardware",
    type: "slider",
  },
  {
    title:
      "Per quanto tempo intendi sfruttare <br><span class='text-red'>il tuo dispositivo?</span> ",
    description:
      "La longevità di un dispositivo è influenzata dalla qualità dei materiali, la frequenza di aggiornamenti software e la svalutazione del device. Se assegni un voto più alto ti mostreremo dispositivi con una maggiore longevità.",
    shortName: "durabilita",
    displayName: "longevità",
    type: "slider",
  },
  {
    title:
      "Quanto è importante <br><span class='text-red'>per te la qualità prezzo?</span> ",
    shortName: "qualitaPrezzo",
    description:
      "Il rapporto qualità prezzo è influenzato dalla valutazione delle caratteristiche hardware e software in base alla fascia di prezzo dei dispositivi. Se assegni un voto più basso ti mostreremo il meglio presente sul mercato senza badare a spese.",
    displayName: "Qualità prezzo",
    type: "slider",
  },
  {
    title: "Il tuo device",
    description:
      "Ecco i tre migliori devices che soddisferanno a pieno le tue esigenze! Gli articoli personalizzati che ti presentiamo, sono scelti e analizzati con cura, rappresentano le migliori offerte del momento e gli articoli più affidabili di Amazon, perciò salvali direttamente nel tuo carrello e acquista quando e se lo ritieni necessario, in questo modo eviterai di perdere il device che fa per te!",
    shortName: "Il tuo device",
  },
];

export const brands = [
  "Apple",
  "Huawei",
  "Samsung",
  "OnePlus",
  "Xiaomi",
  "Motorola",
  "Google",
  "LG",
];

export const defaultUserAnswer = {
  brands: [],
  prezzo: [100, 1500],
  type: "smartphone",
  display: 5,
  fotocamera: 5,
  prestazioni: 5,
  batteria: 5,
  hardware: 5,
  durabilita: 5,
  qualitaPrezzo: 5,
};
